<template>
    <div class="layout flex flex-column w-full bg-gray-200 h-full">
        <div class="underlay flex flex-column w-full align-items-center justify-content-center">
            <CodeInput :uid="uid"></CodeInput>
            <component v-if="!isLoading" :uid="uid" :is="activeComponent" />
            <Loading :active="isLoading" :lock-scroll="true" :is-full-page="true" />
        </div>
    </div>
</template>
<script lang="ts">
import { useAuth } from '@/composables/use-auth';
import { useEnv } from '@/composables/use-env';
import { computed, defineAsyncComponent, defineComponent, onMounted, reactive, Ref, ref, watch } from 'vue';

const CodeInput = import('@components/CodeInput/Index.vue');
const OrderInfo = import('@components/OrderInfo/Index.vue');
const OrderPlaceholder = import('@components/OrderPlaceholder/Index.vue');

import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
    components: {
        CodeInput: defineAsyncComponent(() => Promise.resolve(CodeInput)),
        OrderInfo: defineAsyncComponent(() => Promise.resolve(OrderInfo)),
        OrderPlaceholder: defineAsyncComponent(() => Promise.resolve(OrderPlaceholder)),
    },
    setup(props, ctx) {
        const route = useRoute();
        const store = useStore();
        const auth = useAuth(store);
        const env = useEnv();
        const isLoading: Ref<boolean> = ref(false);

        const uid: any = route.params.uid;
        const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);
        const activeComponent = computed(() => {
            return store.getters['auth/isAuthenticated'] ? 'OrderInfo' : 'OrderPlaceholder';
        });

        onMounted(async () => {
            await store.dispatch('app/GetFreeDays').catch((err: any) => {});
            const jwtKey = env.get('JWT_LS_NAME') || 'koutletcontrol';
            if (localStorage.getItem(jwtKey)) {
                isLoading.value = true;
                try {
                    const result = await auth.login();
                    if (uid && result.orders_control?.uid && uid != result.orders_control?.uid) {
                        return auth.logout().finally(() => (isLoading.value = false));
                    }
                    isLoading.value = false;
                } catch (err) {
                    return auth.logout().then(() => (isLoading.value = false));
                }
            }
        });

        return { activeComponent, uid, isAuthenticated, isLoading };
    },
});
</script>
<style lang="scss">
.underlay {
    top: -23rem;
    display: block;
    position: relative;
}

.layout {
    min-height: 100vh;
}
</style>
