import 'normalize.css';
import 'primevue/resources/primevue.min.css';
// import 'primevue/resources/themes/saga-orange/theme.css';
import '@/assets/style/themes/ordercontrol/theme.scss';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import '@/assets/style/style.scss';
import 'v3-tour/dist/vue-tour.css';
import 'vue-loading-overlay/dist/vue-loading.css';

import { createApp } from 'vue';
// import { VueReCaptcha } from 'vue-recaptcha-v3';
import Toast, { POSITION } from 'vue-toastification';
import VueLoading from 'vue-loading-overlay';
import VueGtag from 'vue-gtag';
// @ts-ignore
import VueTour from 'v3-tour';
// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
import VueSimpleAlert from 'vue3-simple-alert-next';
import Vue3Transitions from 'vue3-transitions';
import VueScreen from 'vue-screen';

//@ts-ignore
import VueClipboard from 'vue3-clipboard';
import moment from 'moment-timezone';
import 'moment/locale/ro'; // without this line it didn't work

import App from './App.vue';
import router from './router';
import store from './store';
import { FeathersVuex } from '@feathersjs/vuex';
import primevuelocale from '@lang/primevue';
import { useEnv } from '@composables/use-env';
import NavHooks from '@/plugins/nav-hooks';
import ErrorsContainer from '@components/ErrorsContainer/Index.vue';
import RoleButton from '@components/RoleButton/Index.vue';
import ButtonLink from '@components/ButtonLink/Index.vue';

import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import AutoComplete from 'primevue/autocomplete';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';
import ProgressSpinner from 'primevue/progressspinner';
import StyleClass from 'primevue/styleclass';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Toolbar from 'primevue/toolbar';
import TabMenu from 'primevue/tabmenu';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';

const env = useEnv();
const app = createApp(App);
app.use(store);
app.use(FeathersVuex);
app.use(router);
app.use(
    VueGtag,
    {
        appName: 'KEYS OUTLET Order control',
        pageTrackerScreenviewEnabled: true,
        config: {
            id: 'UA-212145236-2',
        },
    },
    router
);

// app.use(NavHooks, { store, router });
app.use(Toast, {
    position: POSITION.BOTTOM_CENTER,
    transition: 'Vue-Toastification__fade',
    pauseOnFocusLoss: false,
    maxToasts: 5,
    newestOnTop: false,
    icon: false,
});
moment.tz.setDefault('Europe/Bucharest');
moment.locale('ro');
app.provide('moment', moment);
app.use(PrimeVue, { locale: primevuelocale, ripple: true });
app.use(ConfirmationService);
// app.use(VueReCaptcha, { siteKey: env.get('RECAPTCHA_SITE_KEY'), loaderOptions: { autoHideBadge: true } });
app.use(VueClipboard, { autoSetContainer: true, appendToBody: true });
app.use(VueSimpleAlert, { reverseButtons: true });
app.use(VueTour);
app.use(Vue3Transitions);
app.use(VueScreen);

app.provide('confirm', app.config.globalProperties.confirm);
app.provide('prompt', app.config.globalProperties.prompt);

// Sentry.init({
//     app,
//     logErrors: true,
//     dsn: 'https://f5c879aaa8bc4862992eec774672cd6d@sentry.kout.ro/2',
//     integrations: [
//         new BrowserTracing({
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//             // tracePropagationTargets: ['localhost', 'my.kout.ro', '10.10.2.131', /^\//],
//         }),
//         new Sentry.Integrations.Breadcrumbs({
//             console: false,
//         }),
//     ],
//     beforeSend(event: Sentry.Event, hint: Sentry.EventHint) {
//         const error: any = hint.originalException;
//         /**
//          * Oprim eventul daca nu avem exceptie
//          */
//         if (
//             error.reqid ||
//             error.canceled ||
//             error.cancel ||
//             error.error ||
//             error.name === 'LocalError' ||
//             error.message === 'connection already closed'
//         )
//             return null;
//
//         const user = {};
//         // if (store.state.user != null && store.state.user.info != null && store.state.user.info.email != null) {
//         //     user.email = store.state.user.info.email;
//         // }
//         // if (store.state.user != null && store.state.user.info != null && store.state.user.info.name != null) {
//         //     user.name = store.state.user.info.name;
//         // }
//         // let translation;
//         // if (store.getters['app/currentLang'] === 'ro') {
//         // 	var translation = {
//         // 		title: 'Se pare ca ai gasit o eroare.',
//         // 		subtitle: 'Departamentul tehnic a fost deja notificat.',
//         // 		subtitle2: 'Daca vrei sa ne ajuti cu mai multe detalii, completeaza formularul de mai jos.',
//         // 		labelName: 'Nume',
//         // 		labelEmail: 'Email',
//         // 		labelComments: 'Ce s-a intamplat?',
//         // 		labelClose: 'Inchide',
//         // 		labelSubmit: 'Trimite',
//         // 		errorGeneric: 'A intervenit o eroare. Incearca din nou.',
//         // 		errorFormEntry: 'Unele campuri sunt invalide.',
//         // 		successMessage: 'Formularul a fost trimis. Multumim!'
//         // 	};
//         // }
//         /**
//          * 19.12.2019 AlinT
//          * Dezactivat momentan pentru ca avem multe erori false
//          */
//         // Sentry.showReportDialog({
//         // 	eventId: event.event_id,
//         // 	user,
//         // 	...translation
//         // })
//
//         event.extra = event.extra || {};
//         event.extra = {
//             ...event.extra,
//             version: store.getters['app/appVersion'] || 1,
//         };
//         return event;
//     },
//     environment: env.get('NODE_ENV'),
//     ignoreErrors: [
//         'ResizeObserver loop limit exceeded',
//         'ResizeObserver loop completed with undelivered notifications',
//     ],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampler: () => (process.env.NODE_ENV === 'production' ? 1 : 0.2),
// });

app.provide(
    'reportSentry',
    (error: any, context: any = {}) => false
    // Sentry.withScope((scope) => {
    //     const extra = {
    //         ...(context?.extra || {}),
    //         version: store.getters['app/appVersion'],
    //         appState: (store as any).state.app,
    //         order: (store as any).state.orders,
    //     };
    //     if (error && (!error.stack || error.reqid || error.error)) {
    //         // Generam prea multe erori false
    //         extra.error = error;
    //         error = error.reqid
    //             ? new Error(
    //                   Object.prototype.toString.call(error.message) === '[object String]'
    //                       ? error.message
    //                       : 'Response error'
    //               )
    //             : error;
    //     }
    //     if (context?.tags != null) {
    //         scope.setTags({
    //             ...context.tags,
    //         });
    //     }
    //     scope.setExtras(extra);
    //     Sentry.captureException(error);
    // })
);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Badge', Badge);
app.component('BlockUI', BlockUI);
app.component('Button', Button);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Menu', Menu);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('Password', Password);
app.component('RadioButton', RadioButton);
app.component('SelectButton', SelectButton);
app.component('Skeleton', Skeleton);
app.component('SplitButton', SplitButton);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Tag', Tag);
app.component('TabView', TabView);
app.component('TabMenu', TabMenu);
app.component('TabPanel', TabPanel);
app.component('Textarea', Textarea);
app.component('Toolbar', Toolbar);
app.component('ErrorsList', ErrorsContainer);
app.component('RoleButton', RoleButton);
app.component('Loading', VueLoading);
app.component('ButtonLink', ButtonLink);

app.mount('#app');

export default app;
