import { createStore } from 'vuex';
import app from './app.store';
import auth from './auth.store';
import ksconnect from './ksconnect.store';

// Synchronously import all service modules from the './services' directory
const modulesFiles: Record<string, any> = import.meta.glob('./services/*.ts', { eager: true });
const servicePlugins = Object.keys(modulesFiles).map((key) => modulesFiles[key].default);

// Create the Vuex store with synchronous imports
const store = createStore({
    plugins: [...servicePlugins, auth],
    modules: { app, ksconnect },
    strict: true,
});

// Export the store directly
export default store;
