<template>
    <div class="errors py-0" v-if="errors != null && errors.length > 0">
        <transition-group name="p-message" tag="div">
            <Message v-for="err of errors" :closable="closable" severity="error" :key="err.id"
                ><div v-html="err.message"></div
            ></Message>
        </transition-group>
    </div>
</template>
<script setup lang="ts">
import { toRefs, withDefaults, defineProps, defineEmits, watch } from 'vue';

interface Props {
    errors?: Array<{ id: number; message: string }>;
    closable?: boolean;
}
const props = withDefaults(defineProps<Props>(), { closable: true });

const { errors, closable } = toRefs(props);
</script>
<style lang="scss" scoped>
::v-deep .p-message-text {
    line-height: 1.5;
}
</style>
