<template>
    <Button
        :disabled="isDisabled || (!hasSubscriptions && requiresSubscription)"
        :label="label"
        :icon="icon"
        :iconPos="iconPos"
        :badge="badge"
        :badgeClass="badgeClass"
        :loading="loading"
        :loadingIcon="loadingIcon"
    ></Button>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref, Ref, toRef, toRefs, watch } from 'vue';
import { useStore } from 'vuex';

import useStoreModule from '@composables/use-store';
export default defineComponent({
    props: {
        label: {
            type: String,
        },
        icon: {
            type: String,
        },
        iconPos: {
            type: String,
            default: 'left',
        },
        badge: {
            type: String,
        },
        badgeClass: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loadingIcon: {
            type: String,
            default: 'pi pi-spinner pi-spin',
        },
        requiresSubscription: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const store = useStore();
        const isDisabled: Ref = ref(false);
        const { getters } = useStoreModule('app');
        const hasSubscriptions = computed(() => store.getters['auth/hasSubscriptions']);

        onMounted(() => getters.connectionStatus || onConnectionLost());
        watch(
            () => getters.connectionStatus,
            (value: boolean) => {
                if (!value) onConnectionLost();
                else onConnectionEstablished();
            }
        );

        const onConnectionLost = () => {
            isDisabled.value = true;
        };

        const onConnectionEstablished = () => {
            isDisabled.value = false;
        };

        return {
            isDisabled,
            hasSubscriptions,
            ...toRefs(props),
        };
    },
});
</script>
<style></style>
