import { Emitter } from '@/models/emitter.class';
import { models } from '@feathersjs/vuex';

const LS_INPUT_KEY = 'lsinput';

export const useAuth = (store: any) => {
    const emitter = new Emitter();
    const on = emitter.on.bind(emitter);

    const login = async (credentials?: any) => {
        if (!credentials) {
            const result = await store.dispatch('auth/authenticate');
            return result;
        } else {
            const result = await store.dispatch('auth/authenticate', {
                strategy: 'local',
                ...credentials,
            });
            if (credentials.code) {
                savePINToLocalStorage(credentials.code);
            }
            return result;
        }
    };

    const savePINToLocalStorage = (pin: string) => window.localStorage.setItem(LS_INPUT_KEY, pin);

    const logout = async () =>
        store
            .dispatch('auth/logout')
            .catch(() => {})
            .finally(() => removeSavedPIN());

    const hasPINSaved = () => window.localStorage.getItem(LS_INPUT_KEY) != null;

    const getSavedPIN = () => window.localStorage.getItem(LS_INPUT_KEY);

    const removeSavedPIN = () => window.localStorage.removeItem(LS_INPUT_KEY);

    return {
        login,
        logout,
        on,
        hasPINSaved,
        getSavedPIN,
    };
};
