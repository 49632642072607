import { useEnv } from '../../composables/use-env';
import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client';
class Orders extends BaseModel {
    constructor(data: any, options: any) {
        super(data, options);
    }
    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = 'Orders';
    // Define default properties here
    static instanceDefaults() {
        return {};
    }
}

const env = useEnv();

const servicePath = 'orders';
const servicePlugin = makeServicePlugin({
    Model: Orders,
    service: feathersClient.service(servicePath),
    servicePath,
    nameStyle: 'path',
    debug: env.is('NODE_ENV', 'development'),
    idField: 'id',
    enableEvents: true,
    state: {
        orderData: {},
        reference: null,
        billing: {},
        changeRequests: [],
        products: [],
    },
    mutations: {
        SetOrderData: (state: any, data: any) => {
            state.orderData = data;
        },
        SetOrderBilling: (state: any, data: any) => {
            state.billing = data;
        },
        SetInvoiceChangeRequests: (state: any, data: any) => {
            state.changeRequests = data;
        },
        SetOrderProducts: (state: any, data: any) => {
            state.products = data;
        },
    },
    getters: {
        billing: (state: any) => state.billing,
        reference: (state: any) => state.orderData?.reference,
        display: (state: any) => state.orderData?.display,
        changeRequests: (state: any) => state.changeRequests,
        products: (state: any) => state.products,
        invoices: (state: any) => state.orderData?.invoices,
        activationWarranty: (state: any) => ({
            activationWarrantyInDays: state?.orderData?.marketplace?.activationWarranty,
            activatinWarrantyExpirationDate: state?.orderData?.marketplace?.warrantyExpirationDate,
            activationWarrantyDaysRemaining: state?.orderData?.marketplace?.activationWarrantyDaysRemaining,
        }),
        isCorder: (state: any) => state.orderData?.corder == 1,
        isReturned: (state: any) => state.orderData?.returned == 1,
    },
    actions: {},
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
});

export default servicePlugin;
