<template>
    <div class="flex flex-column w-full footer justify-content-end lg:static">
        <div class="surface-section px-0 md:px-3 lg:px-3">
            <div
                class="surface-section py-3 md:py-6 px-0 md:px-3 flex flex-column md:flex-row md:align-items-center align-items-start justify-content-center"
            >
                <div class="mt-3 md:mt-0 px-5 lg:px-8 pb-4 md:pb-0">
                    <img src="/images/logo_keys_bandw.svg" alt="Image" height="50" />
                </div>
                <!-- <div class="hidden md:flex flex-nowap text-base md:text-left px-6 pb-4 md:pb-0">
                    KEYS OUTLET GROUP S.R.L.<br />
                    CIF: RO44527720<br />
                    Banca Transilvania: RO03BTRLRONCRT0608998001<br />
                </div> -->
                <div class="flex flex-nowap flex-row align-items-center medium px-5 lg:px-8 pb-4 md:pb-0">
                    <i class="pi pi-envelope mr-3 p-3 border- text-gray-800 text-xl footer-icon"></i>
                    <span class="text-base md:text-lg font-bold cursor-pointer">
                        <a target="_blank" href="mailto:contact@keysoutlet.ro">contact@keysoutlet.ro</a>
                    </span>
                </div>
                <div class="flex flex-nowap flex-row align-items-center font-medium px-5 lg:px-8 pb-3 md:pb-0">
                    <i class="pi pi-phone mr-3 p-3 border- text-gray-800 text-xl footer-icon"></i>
                    <span class="text-base md:text-lg font-bold cursor-pointer">
                        <a target="_blank" href="tel:+40371239559">+40 371 239 559</a>
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-gray-200 py-3">Copyright © {{ currentYear }} {{ company }}. Toate drepturile rezervate.</div>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
    setup(props, ctx) {
        const currentYear = computed(() => new Date().getFullYear());
        const store = useStore();
        const company = computed(() => (store.getters['orders/isCorder'] ? 'BRAND DESIGN TEAM S.R.L.' : 'KEYS OUTLET'));
        return { currentYear, company };
    },
});
</script>
<style lang="scss">
.footer {
    height: 25rem;
    background-image: url('/images/bgcropped.webp');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: auto;
}

.footer-icon {
    background-color: var(--primary-color);
    border-radius: 3px;
}
</style>
