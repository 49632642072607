import feathers, { HookContext } from '@feathersjs/feathers';
// import socketio from '@feathersjs/socketio-client';
import rest from '@feathersjs/rest-client';
import axios from 'axios';
import auth from '@feathersjs/authentication-client';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
// import io from 'socket.io-client/dist/socket.io';
import { iff, discard } from 'feathers-hooks-common';
import feathersVuex from '@feathersjs/vuex';
// import store from '@/store';
// import { useLogger } from '@composables/use-logger';
import { useEnv } from '@composables/use-env';

const env = useEnv();
// const logger = useLogger();
// const socket = (io as any)(env.get('BACKEND_URL'), {
//     transports: ['websocket'],
//     forceNew: true,
// });

const axiosClient = axios.create({
    timeout: 120000,
});
const restClient = rest(env.get('BACKEND_URL'));

const feathersClient = (feathers() as any)
    .configure(restClient.axios(axiosClient))
    .configure(
        auth({
            storageKey: env.get('JWT_LS_NAME') as string,
            storage: window.localStorage as any,
            // storage: undefined,
            path: '/authentication/platform',
        })
    )
    .hooks({
        before: {
            all: [
                iff((context) => ['create', 'update', 'patch'].includes(context.method), discard('__id', '__isTemp')),
            ],
        },
        error: {
            all: [
                async (context: HookContext) => {
                    if (context?.error?.code === 401 && context.path !== 'authentication/platform') {
                        window.location.reload();
                    }
                },
            ],
        },
    });

// socket.on('reconnect', () => {
//     logger.info('Reconnected to server');
//     feathersClient.authenticate();
// });

// socket.on('connect', () => {
//     logger.info('Connected to server');
//     store.commit('app/SET_CONNECTION_STATUS', true);
// });

// socket.on('connect_error', (err: any) => {
//     logger.error('Disconnected from server');
//     store.commit('app/SET_CONNECTION_STATUS', false);
// });

export default feathersClient;

// Setting up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(feathersClient, {
    serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
    idField: 'id', // Must match the id field in your database table/collection
    whitelist: ['$regex', '$options'],
    // enableEvents: (process as any).client, // Prevent memory leak
});

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex };
