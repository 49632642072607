import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AppMain from '@/views/AppMain.vue';
const NotFound = () => import('@/views/404.vue');
const Unsubscribe = () => import('@/views/Unsubscribe.vue');
const routes: Array<RouteRecordRaw> = [
    {
        path: '/unsubscribe/:uid',
        name: 'Unsubscribe',
        component: Unsubscribe,
    },
    {
        path: '/:uid',
        name: 'AppMain',
        component: AppMain,
    },
    { path: '/:pathMatch(.*)*', component: NotFound },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

export default router;
