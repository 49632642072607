import { useEnv } from '@composables/use-env';
import feathersClient from '@/feathers-client';
export interface OrderStep {
    target: string;
    content: string;
    params?: Record<string, any>;
}

export const initialAppStore = () => {
    return {
        ksConnect: {
            enabled: false,
            url: null,
        },
        connectionStatus: true,
        collectInitialized: false,
        contactModal: false,
        freeDays: [],
        orderTour: {
            tourStarted: false,
            steps: [],
            stepsRequired: 7,
        },
    };
};

const getters = {
    connectionStatus: (state: any) => state.connectionStatus,
    contactModal: (state: any) => state.contactModal,
    collectStatus: (state: any) => state.collectInitialized,
    orderTourReady: (state: any) => state.orderTour.steps.length === state.orderTour.stepsRequired,
    orderTourStarted: (state: any) => state.orderTour.tourStarted,
    orderTourSteps: (state: any) => state.orderTour.steps,
    freeDays: (state: any) => state.freeDays,
};
const actions = {
    GetFreeDays: async ({ commit }: { commit: any }) => {
        const FreeDaysAPI = feathersClient.service('freedays');
        try {
            const result = await FreeDaysAPI.find({ query: {} });
            commit('SET_FREE_DAYS', result);
        } catch (err: any) {
            console.log(err);
        }
    },
};

const mutations = {
    RESET_APP: (state: any) => {
        const initial: any = initialAppStore();
        Object.keys(state).forEach((key) => {
            state[key] = initial[key];
        });
    },

    TOGGLE_LOADING: (state: any, status: any) => {
        state.bodyLoading = status;
    },
    SET_CONNECTION_STATUS: (state: any, status: any) => {
        state.connectionStatus = status;
    },
    SET_COLLECT_INIT: (state: any, status: any) => (state.collectInitialized = status),
    SET_CONTACT_MODAL: (state: any, status: any) => (state.contactModal = status),
    ADD_TOUR_STEP: (state: any, step: OrderStep) => {
        if (
            !state.orderTour.steps.some((s: OrderStep) => s.target === step.target) &&
            document.querySelector(step.target)
        ) {
            state.orderTour.steps.push(step);
        }
    },
    SET_TOUR_STARTED: (state: any, status: boolean) => (state.orderTour.tourStarted = status),
    SET_FREE_DAYS: (state: any, freeDays: Array<Record<string, string>> = []) => {
        freeDays.sort((a, b) => {
            const [dayA, monthA, yearA] = a.date.split('.').map(Number);
            const [dayB, monthB, yearB] = b.date.split('.').map(Number);

            const dateA = new Date(yearA, monthA - 1, dayA);
            const dateB = new Date(yearB, monthB - 1, dayB);

            return dateA.getTime() - dateB.getTime();
        });
        state.freeDays = freeDays;
    },
};

export default {
    namespaced: true, // Important so we can merge the stores
    state: initialAppStore(),
    mutations,
    getters,
    actions,
};
