import { HookContext } from '@feathersjs/feathers';
import feathersClient, { makeAuthPlugin } from '../feathers-client';
import store from '@/store';
import router from '@/router';

import { useAuth } from '@/composables/use-auth';
feathersClient.service('authentication/platform').hooks({
    after: {
        create: [
            (context: HookContext) => {
                const { service, result } = context;
                const auth = useAuth(store);
                if (
                    router.currentRoute.value?.params?.uid &&
                    result.orders_control?.uid &&
                    router.currentRoute.value?.params.uid != result.orders_control?.uid
                ) {
                    return auth.logout(), context;
                }

                return context;
            },
        ],
    },
});

export default makeAuthPlugin({
    userService: 'orders/control',
    responseEntityField: 'orders_control',
    entityIdField: 'id',
    getters: {
        orderId: (state: any) => state?.user?.orderId,
    },
});
