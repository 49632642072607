import { useEnv } from '@composables/use-env';
import axios from 'axios';
import DeviceDetector from 'device-detector-js';

export const initialKSConnectStore = () => {
    return {
        enabled: false,
        serviceUrl: null,
        possiblePorts: [50005, 50001, 50000, 50003, 50004],
    };
};

const getters = {
    serviceUrlIsSet: (state: any) => {
        return state.serviceUrl != null;
    },
};
const actions = {
    Setup: async ({ commit, getters, state }: { commit: any; dispatch: any; state: any }) => {
        const env = useEnv();
        const host = env.get('KSCONNECT_HOST');
        if (!host) {
            console.log('KSConnect host not set');
            return;
        }
        // Check if the service is available
        for (const port of state.possiblePorts) {
            // Check if the service is available
            const url = `http://${host}:${port}`;
            const response = await axios.head(`${url}/health`, { timeout: 2000 }).catch(() => null);
            if (response?.status == 200) {
                console.log('KSConnect service found at:', url);
                commit('SetUrl', url);
                break;
            } else {
                console.log('KSConnect service not found at:', url);
            }
        }
        if (!getters.serviceUrlIsSet) {
            console.log('KSConnect service not found');
            return;
        }

        // Check if we are on desktop and it's windows
        const deviceDetector = new DeviceDetector();
        const device = deviceDetector.parse(window.navigator.userAgent);
        console.log(device);
        if (device?.device?.type != 'desktop') {
            console.log('KSConnect is disabled on mobile devices');
            return;
        }
        if (device?.os?.name != 'Windows') {
            console.log('KSConnect is disabled on non-windows devices');
            return;
        }
        commit('SetEnabled', true);
        console.log('KSConnect is enabled');
    },
    // GetFreeDays: async ({ commit }: { commit: any }) => {
    //     const FreeDaysAPI = feathersClient.service('freedays');
    //     try {
    //         const result = await FreeDaysAPI.find({ query: {} });
    //         commit('SET_FREE_DAYS', result);
    //     } catch (err: any) {
    //         console.log(err);
    //     }
    // },
};

const mutations = {
    SetUrl(state: any, url: string) {
        state.serviceUrl = url;
    },
    SetEnabled(state: any, enabled: boolean) {
        state.enabled = enabled;
    },
};

export default {
    name: 'ksconnect',
    namespaced: true, // Important so we can merge the stores
    state: initialKSConnectStore(),
    mutations,
    getters,
    actions,
};
