import { useEnv } from '../../composables/use-env';
import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client';
class OrdersControl extends BaseModel {
    constructor(data: any, options: any) {
        super(data, options);
    }
    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = 'OrdersControl';
    // Define default properties here
    static instanceDefaults() {
        return {};
    }
}

const env = useEnv();

const servicePath = 'orders/control';
const servicePlugin = makeServicePlugin({
    Model: OrdersControl,
    service: feathersClient.service(servicePath),
    servicePath,
    nameStyle: 'path',
    debug: env.is('NODE_ENV', 'development'),
    idField: 'id',
    enableEvents: true,
    state: {},
    mutations: {},
    getters: {},
    actions: {},
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
});

export default servicePlugin;
