<template>
    <a href="" id="link" ref="linkRef" target="_blank" class="link-service">X</a>
</template>
<script setup lang="ts">
import { computed, defineComponent, onMounted, reactive, Ref, ref, watch, defineExpose } from 'vue';

const linkRef: Ref<HTMLAnchorElement | null> = ref(null);

const openLink = (lnk: string) => {
    if (linkRef.value != null) {
        linkRef.value.href = lnk;
        document.getElementById('link')?.click();
    }
};

defineExpose({
    openLink,
});
</script>
<style scoped>
.link-service {
    position: absolute;
    top: 0;
    display: none;
}
</style>
