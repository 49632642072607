export default {
    startsWith: 'Incepe cu',
    contains: 'Contine',
    notContains: 'Nu contine',
    endsWith: 'Se termina cu',
    equals: 'Este egal cu',
    notEquals: 'Nu este egal cu',
    noFilter: 'Fara filtru',
    lt: 'Mai mic de',
    lte: 'Mai mic sau egal cu',
    gt: 'Mai mare decat',
    gte: 'Mai mare sau egal cu',
    dateIs: 'Data este',
    dateIsNot: 'Data nu este',
    dateBefore: 'Data este inainte de',
    dateAfter: 'Data este dupa',
    clear: 'Sterge',
    apply: 'Aplica',
    matchAll: 'Se potriveste cu',
    matchAny: 'Se potriveste cu oricare',
    addRule: 'Adauga regula',
    removeRule: 'Sterge regula',
    accept: 'Da',
    reject: 'Nu',
    choose: 'Alege',
    upload: 'Incarca',
    cancel: 'Anuleaza',
    dayNames: ['Duminca', 'Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata'],
    dayNamesShort: ['Dum', 'Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sam'],
    dayNamesMin: ['Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sa'],
    monthNames: [
        'Ianuarie',
        'Februarie',
        'Martie',
        'Aprilie',
        'Mai',
        'Iunie',
        'Iulie',
        'August',
        'Septembrie',
        'Octombrie',
        'Noiembrie',
        'Decembrie',
    ],
    monthNamesShort: ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Noi', 'Dec'],
    today: 'Astazi',
    weekHeader: 'Sapt',
    firstDayOfWeek: 0,
    dateFormat: 'dd/mm/yyyy',
    weak: 'Slab',
    medium: 'Mediu',
    strong: 'Puternic',
    passwordPrompt: 'Scrie o parola',
    emptyFilterMessage: 'Nici un rezultat gasit',
    emptyMessage: 'Nu exista optiuni disponibile',
};
